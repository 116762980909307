#contact {
	background-image: url('../images/contactBackground.png');

	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}
.logoContact {
	display: flex;
}

.logo {
	padding-top: 1vw;
	padding-left: 3vw;
	padding-bottom: 1vw;
	height: 9rem;
}

.contact {
	padding-top: 3vw;
	padding-left: 2vw;
}

.contact p {
	margin-top: 0;
	margin-bottom: 0;
}

.contact img {
	height: 35px;
}

#navbar {
	padding-right: 2vw;
	display: flex;
	flex-direction: column;
}
