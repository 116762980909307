#catering {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	padding-bottom: 3rem;
	background-image: url('../images/cateringBackground.png');
	margin: 5vw 5vw 5vw 5vw;
	overflow: hidden;
}

/****CATERING IMAGES****/
#catering .cart {
	height: 38vh;
	border-style: solid;
	border-width: 0.5rem;
	border-radius: 8px;
	border-color: rgb(207, 83, 26);
	opacity: 0.9;
	margin-top: -15%;
	margin-left: 8%;
}

#catering .chocolate {
	height: 38vh;
	border-style: solid;
	border-width: 0.5rem;
	border-radius: 8px;
	border-color: rgb(207, 144, 26);
	opacity: 0.8;
	margin-top: 5%;
	margin-left: 49%;
}

#catering .foodTruck {
	height: 38vh;
	border-style: solid;
	border-width: 0.5rem;
	border-radius: 8px;
	color: hsl(192, 97.1%, 26.7%);
	opacity: 1;
	margin-top: -5%;
	margin-left: 46%;
}
