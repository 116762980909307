#footer {
	display: flex;
	justify-content: center;
}

#footer a {
	text-decoration: none;
	font-family: 'Ranchers';
	color: white;
	text-shadow: -2px 2px 0 #000, 1px -1px 0 #000, -2px 2px 0 #000;
	text-decoration: none;
	cursor: pointer;
	transition: color 1s;
}

#footer a:hover {
	color: orange;
}
