#about {
	display: flex;
	flex-wrap: wrap;
	transform: translateY(-3em);
	justify-content: space-around;
}

#about h1 {
	margin-top: 0;
	margin-bottom: 0;
}

.aboutOne {
	max-width: 40%;
}

.aboutTwo {
	max-width: 40%;
}
